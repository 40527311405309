import React from "react"
import { Link } from "gatsby"

import './footer.css'

const Footer = () => (
    <footer className="footer">
        <nav className="footer__nav">
            <ul className="nav__list">
                <li className="nav__item">
                    <a className="nav__item__link" href="http://eepurl.com/g02Sc9" target="_blank">Subscribe</a>
                </li>
                <li className="nav__item">
                    <Link to="/imprint">Imprint</Link> 
                </li>
            </ul>          
        </nav>
    </footer>
)

export default Footer

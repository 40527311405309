import React from "react"
import { Helmet } from "react-helmet"

import Header from "./header/header"
import Footer from "./footer/footer"

const Layout = (props) => {  
    return (
      <>
        <Helmet>
            <meta charSet="utf-8" />
            <title>Cocktail International</title>
            <meta name="description" content="" />
        </Helmet>
        <Header />
        {props.children}
        <Footer />
      </>
    )
}
  
export default Layout
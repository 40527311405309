import React from "react"
import { Link } from "gatsby"

import './header.css'

const Header = () => (
    <header className="header">
        <nav className="header__nav">
            <ul className="nav__list">
                <li className="nav__item">
                    <a className="nav__item__link" href="mailto:lukas@cocktail.international?body=Hi%20i%20I%20love%20your%20work">Contact</a>
                </li>
                <li className="nav__item">
                    <a className="nav__item__link" href="https://www.instagram.com/cocktailinternational" target="_blank">Instagram</a>
                </li>
                <li className="nav__item">
                    <a className="nav__item__link" href="https://soundcloud.com/cocktailinternational" target="_blank">Sound</a>
                </li>
            </ul>
        </nav>
    </header>
)

export default Header
